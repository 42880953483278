import React from "react";

import FileUploader from "../../../components/file-uploader/FileUploader";
import Button from "../../../components/button/Button";

const IdentityVerification = () => {
  return (
    <div className="u-col u-gap16">
      <p>
        本人確認書類を提出して「本人確認済み」ラベルを獲得。信頼性を高めましょう。
      </p>
      <FileUploader />
      <Button
        name="本人確認書類を提出"
        size="xl"
        type="primary"
        rightIcon="chevron_right"
      />
    </div>
  );
};

export default IdentityVerification;
