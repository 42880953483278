import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux"; // Import useSelector to access Redux state

import Avatar from "../../avatar/Avatar";
import { getListMenuFromUser } from "../common/utils";

const GlobalMenu = styled.div`
  height: 64px;
  position: sticky;
  transition: top 0.2s;
  top: ${({ headerTop }) => `${headerTop}px`};
  z-index: 6;
  background: rgba(255, 255, 255, 0.16);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
`;

const AuthorizedHeader = ({ user }) => {
  const listMenu = getListMenuFromUser({ user });

  // Access the scroll state from Redux - you don't have to trigger addEventListener everywhere anymore, only need in global Wraper
  const scrollState = useSelector((state) => {
    return state.layout;
  });

  // Set headerTop based on scroll state
  const headerTop = scrollState === "up" ? 0 : scrollState === "down" ? -64 : 0;

  return (
    <GlobalMenu className="u-w100 u-row-center" headerTop={headerTop}>
      <div className="u-wrapper u-row-end u-gap32">
        <ul className="u-row-end u-gap32">
          {listMenu.map((menuInfo) => (
            <li key={menuInfo.link}>
              <a href={menuInfo.link} dataShow={menuInfo.name}>
                {menuInfo.label}
              </a>
            </li>
          ))}
        </ul>
        <Link to="/my-page">
          <Avatar
            size={40}
            src={user.medium_avatar}
            isOnline={user.is_online}
          />
        </Link>
      </div>
    </GlobalMenu>
  );
};

export default AuthorizedHeader;
