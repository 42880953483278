import React from "react";

import Input from "../../../components/form-item/Input";

const MySiteTopic = () => {
  return (
    <div className="u-col u-gap16">
      <p>トピックの説明文</p>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          1.414:1のアスペクト比で画像をアップロードしましょう。
        </div>
        <div className=" u-w100">
          <div className="u-row u-gap4">
            <div className="heading">サムネイル</div>
            <span className="label8 u-text-light-gray">[任意]</span>
          </div>

          <div className="u-col u-gap8 u-w100">
            <div
              style={{
                width: "50px",
                height: "70.7px",
                backgroundColor: "lightgray",
                borderRadius: 4,
              }}
            ></div>
            <div
              style={{
                width: "100%",
                height: "5rem",
                backgroundColor: "gray",
                borderRadius: 4,
              }}
            >
              ファイル選択用コンポーネント
            </div>
          </div>
        </div>
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">動画をアップロードしましょう。</div>
        <div className=" u-w100">
          <div className="u-row u-gap4">
            <div className="heading">動画</div>
            <span className="label8 u-text-light-gray">[任意]</span>
          </div>

          <div className="u-col u-gap8 u-w100">
            <div
              style={{
                width: "100%",
                height: "5rem",
                backgroundColor: "gray",
                borderRadius: 4,
              }}
            >
              ファイル選択用コンポーネント
            </div>
          </div>
        </div>
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          16:9のアスペクト比で画像をアップロードしましょう。
        </div>
        <div className=" u-w100">
          <div className="u-row u-gap4">
            <div className="heading">キービジュアル</div>
            <span className="label8 u-text-light-gray">[任意]</span>
          </div>

          <div className="u-col u-gap8 u-w100">
            <div
              style={{
                width: "160px",
                height: "90px",
                backgroundColor: "lightgray",
                borderRadius: 4,
              }}
            ></div>
            <div
              style={{
                width: "100%",
                height: "5rem",
                backgroundColor: "gray",
                borderRadius: 4,
              }}
            >
              ファイル選択用コンポーネント
            </div>
          </div>
        </div>
      </div>

      <div className="u-col u-w100">
        {/* <div className="bodytext-11">トピックタイトル説明文</div> */}
        <Input
          label="トピックタイトル"
          option="[必須]"
          type="text"
          placeholder="トピックタイトル"
          width="100%"
          append=""
          name=""
        />
      </div>

      <div className="u-col u-w100">
        <Input
          label="構成要素"
          option="[任意]"
          type="text"
          placeholder="インタラクティブミュージック　効果音　オーディオガイド　番組配信"
          width="100%"
          append=""
          name=""
        />
        <div className="bodytext-11">
          ワードの区切りは、スペースで設定してください。
        </div>
      </div>

      <div className="u-col u-w100">
        {/* <div className="bodytext-11">説明文</div> */}
        <Input
          label="概要"
          option="[任意]"
          type="text"
          placeholder="展示会の空間サウンドデザイン、オーディオガイドを作成します。"
          width="100%"
          append=""
          name=""
        />
      </div>

      <div className="u-col u-w100">
        {/* <div className="bodytext-11">説明文</div> */}
        <Input
          label="詳細"
          option="[任意]"
          type="text"
          placeholder="展示会やプロジェクションマッピング、インスタレーション作品のサウンド演出にもっと向き合ってみませんか。"
          width="100%"
          append=""
          name=""
        />
      </div>

      <div className="u-col u-w100">
        {/* <div className="bodytext-11">ワードの区切りは、#で設定してください。</div> */}
        <Input
          label="ハッシュタグ"
          option="[任意]"
          type="text"
          placeholder="ワードの区切りは「#」で設定してください。 #メタバース #ソニックブランディング"
          width="100%"
          append=""
          name=""
        />
      </div>

      <div className="u-col u-w100">
        {/* <div className="bodytext-11">説明文</div> */}
        <div className=" u-w100">
          <div className="u-row u-gap4">
            <div className="heading">詳細資料</div>
            <span className="label8 u-text-light-gray">[任意]</span>
          </div>

          <div className="u-col u-gap8 u-w100">
            <div
              style={{
                width: "100%",
                height: "5rem",
                backgroundColor: "gray",
                borderRadius: 4,
              }}
            >
              ファイル選択用コンポーネント
            </div>
          </div>
        </div>
      </div>

      <div className="u-col u-w100">
        {/* <div className="bodytext-11">説明文</div> */}
        <div className=" u-w100">
          <div className="u-row u-gap4">
            <div className="heading">選択項目</div>
            <span className="label8 u-text-light-gray">[任意]</span>
          </div>

          <div className="u-col u-gap8 u-w100">
            <div
              style={{
                width: "100%",
                height: "5rem",
                backgroundColor: "gray",
                borderRadius: 4,
              }}
            >
              選択項目を追加するコンポーネント（押すとモーダルでメニュー出現）
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MySiteTopic;
