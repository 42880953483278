import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: ${({ size }) => (size === "compact" ? "40px" : "56px")};
  padding: ${({ size }) =>
    size === "section" ? "0px 0px 4px 16px" : "0px 0px 0px 16px"};
  display: flex;
  align-items: ${({ size }) => (size === "section" ? "flex-end" : "center")};
  justify-content: space-between;
  border-bottom: ${({ hasBorderBottom }) =>
    hasBorderBottom ? "1px solid var(--soremo-border)" : "none"};
  background-color: ${({ isActive, isHover }) =>
    isActive ? "#f0f0f0" : isHover ? "#fcfcfc" : "white"};
  cursor: ${({ isHover }) => (isHover ? "pointer" : "default")};
`;

const Section = styled.div.attrs({
  className: "u-row bodytext-13 u-text-light-gray",
})`
  width: 100%;
  height: ${({ size }) => (size === "compact" ? "40px" : "56px")};
  padding: 0px 0px 4px 16px;
  align-items: flex-end;
  border-bottom: ${({ hasBorderBottom }) =>
    hasBorderBottom ? "1px solid var(--soremo-border)" : "none"};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--soremo-light-gray);
`;

const Icon = styled.div.attrs({ className: "material-symbols-rounded" })`
  color: ${({ isHover }) =>
    isHover ? "var(--soremo-light-gray)" : "var(--soremo-border)"};
`;

const AlertText = styled.div.attrs({ className: "bodytext-11" })`
  line-height: 100%;
  color: var(--soremo-green);
  }
`;

export const ListItem = ({
  name,
  size = "default",
  status = "",
  alert = "",
  onClick,
  hasBorderBottom = true,
  icon = "chevron_right",
}) => {
  const [isHover, setIsHover] = useState(false);
  const [isActive, setIsActive] = useState(false);

  return size === "section" ? (
    <Section hasBorderBottom={hasBorderBottom}>{name}</Section>
  ) : (
    <Container
      size={size}
      hasBorderBottom={hasBorderBottom}
      isHover={isHover}
      isActive={isActive}
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onMouseDown={() => setIsActive(true)}
      onMouseUp={() => setIsActive(false)}
    >
      <Content size={size}>
        <label>{name}</label>
        {alert && <AlertText>{alert}</AlertText>}
      </Content>
      <Status>
        <div>{status}</div>
        <Icon isHover={isHover}>{icon}</Icon>
      </Status>
    </Container>
  );
};

export default ListItem;
