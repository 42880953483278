import React from "react";

import Input from "../../../components/form-item/Input";

const MySiteHeader = () => {
  return (
    <div className="u-col u-gap16">
      <p>ヘッダーに表示するコンテンツを設定しましょう。</p>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          キービジュアルの表示形式を選択できます。
        </div>
        <div className="c-group u-w100">
          <div className="u-row u-gap4">
            <div className="heading">キービジュアルの表示形式</div>
            <span className="label8 u-text-blue">[必須]</span>
          </div>
          <div className="u-w100" style={{ overflowX: "scroll" }}>
            <div className=" u-row u-gap16">
              <label className="c-radio-icon" htmlFor="keyvisual-size-01">
                <div
                  className=""
                  style={{
                    backgroundColor: "white",
                    width: "180px",
                    height: "360px",
                    aspectRatio: 1 / 2,
                  }}
                ></div>
                <input
                  type="radio"
                  name="keyvisual-size"
                  id="keyvisual-size-01"
                  defaultChecked="checked"
                />
              </label>
              <label className="c-radio-icon" htmlFor="keyvisual-size-02">
                <div
                  className=""
                  style={{
                    backgroundColor: "white",
                    width: "180px",
                    height: "360px",
                    aspectRatio: 1 / 2,
                  }}
                ></div>
                <input
                  type="radio"
                  name="keyvisual-size"
                  id="keyvisual-size-02"
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          左上に表示するロゴ画像を設定できます。（透過PNG推奨）
        </div>
        <div className="u-row u-gap4">
          <div className="heading">ロゴ</div>
          <span className="label8 u-text-light-gray">[任意]</span>
        </div>
        <div
          style={{
            width: "100%",
            height: "10rem",
            backgroundColor: "gray",
            borderRadius: 4,
          }}
        >
          アップロード用コンポーネント
        </div>
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          フルスクリーンの画像か動画を設定できます。
        </div>
        <div className="u-row u-gap4">
          <div className="heading">キービジュアル（フルスクリーン）</div>
          <span className="label8 u-text-light-gray">[任意]</span>
        </div>
        <div
          style={{
            width: "100%",
            height: "10rem",
            backgroundColor: "gray",
            borderRadius: 4,
          }}
        >
          アップロード用コンポーネント
        </div>
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          スマートフォン閲覧向けの画像か動画も設定できます。
        </div>
        <div className="u-row u-gap4">
          <div className="heading">キービジュアル（スマートフォン向け）</div>
          <span className="label8 u-text-light-gray">[任意]</span>
        </div>
        <div
          style={{
            width: "100%",
            height: "10rem",
            backgroundColor: "gray",
            borderRadius: 4,
          }}
        >
          アップロード用コンポーネント
        </div>
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          バナーに設定する2.35:1（シネスコープ）の画像をアップロードできます。PDF書き出しでも利用します。
        </div>
        <div className="u-row u-gap4">
          <div className="heading">バナー</div>
          <span className="label8 u-text-light-gray">[任意]</span>
        </div>
        <div
          style={{
            width: "100%",
            height: "10rem",
            backgroundColor: "gray",
            borderRadius: 4,
          }}
        >
          アップロード用コンポーネント
        </div>
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">キャッチフレーズを設定できます。</div>
        <Input
          label="キャッチフレーズ"
          option="[任意]"
          type="text"
          placeholder="音で「ワクワク」をデザイン"
          width="100%"
          append=""
          name=""
        />
        <Input
          label="キャッチフレーズ"
          option="[任意]"
          type="text"
          placeholder="Design with Sound"
          width="100%"
          append=""
          name=""
        />
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          アーティスト名とタイトルを表示できます。
        </div>
        <div className="u-w100 u-col u-gap16">
          <div className="u-w100 u-col c-group">
            <div className="u-w100 u-row u-gap16">
              <Input
                label="タグライン1"
                option="[任意]"
                type="text"
                placeholder="山田 花子"
                width="100%"
                append=""
                name=""
              />
              <Input
                type="text"
                placeholder="Yamada Hanako"
                width="100%"
                append=""
                name=""
              />
            </div>
            <div className="u-row u-gap8">
              <input type="checkbox" />
              <div className="heading-13">タグライン1をヘッダーに表示</div>
            </div>
          </div>
          <div className="u-w100 u-col c-group">
            <div className="u-w100 u-row u-gap16">
              <Input
                label="タグライン2"
                option="[任意]"
                type="text"
                placeholder="タイトル"
                width="100%"
                append=""
                name=""
              />
              <Input
                type="text"
                placeholder="Title"
                width="100%"
                append=""
                name=""
              />
            </div>
            <div className="u-row u-gap8">
              <input type="checkbox" />
              <div className="heading-13">タグライン2をヘッダーに表示</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MySiteHeader;
