import React from "react";
import styled from "styled-components";

const MileageCardStyle = styled.div`
  width: 128px;
  aspect-ratio: 1.414/1;
  background-color: #f0f0f0;
  border-radius: 6px;
`;

const MileageBlock = () => {
  return (
    <div className="u-row u-gap8 u-ptb16 u-w100 u-pl16 u-pr16">
      <MileageCardStyle></MileageCardStyle>
      <div className="u-w100 u-col u-gap4">
        <p className="heading-16">
          10,000,000<span className="bodytext-11">マイル</span>
          <span className="bodytext-11 u-text-light-gray">
            （利用料：1.50%）
          </span>
        </p>
        <progress value={70} max={100} style={{ width: "100%" }}></progress>
        <p className="bodytext-11">次のステージまであと10,000,000マイル</p>
      </div>
    </div>
  );
};

export default MileageBlock;
