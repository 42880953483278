import React, { useState } from "react";

import RadioCard from "../../../components/form-item/RadioCard";
import Input from "../../../components/form-item/Input";

const Notification = () => {
  const [checked, setChecked] = useState("now");

  const handleChecked = (id) => {
    setChecked(id);
  };

  return (
    <div className="u-col u-gap16">
      <p>メッセージごとのメール通知タイミングをカスタマイズできます。</p>

      <RadioCard
        label="今すぐ（15分ごとに通知）"
        name="setting_mail"
        id="now"
        width="128px"
        height="128px"
        showRadio
        checked={checked === "now"}
        showImage
        onClick={() => handleChecked("now")}
      />
      <RadioCard
        label="1日1回まとめて通知"
        name="setting_mail"
        id="on"
        width="128px"
        height="128px"
        showRadio
        checked={checked === "on"}
        showImage
        onClick={() => handleChecked("on")}
      />
      <RadioCard
        label="オフ"
        name="setting_mail"
        id="off"
        width="128px"
        height="128px"
        showRadio
        checked={checked === "off"}
        showImage
        onClick={() => handleChecked("off")}
      />
      {checked === "on" && <Input label="配信時間" type="time" value="10:00" />}
    </div>
  );
};

export default Notification;
