import React from "react";

import PaymentDetails from "./components/PaymentDetails";
import TransactionHistory from "./components/TransactionHistory";
import BankAccount from "./components/BankAccount";
import CreditCard from "./components/CreditCard";
import Notification from "./components/Notification";
import OfferConditions from "./components/OfferConditions";
import PersonalInformation from "./components/PersonalInformation";
import Schedule from "./components/Schedule";
import BlockList from "./components/BlockList";
import MySite from "./components/MySite";
import EmailPassword from "./components/EmailPassword";
import IdentityVerification from "./components/IdentityVerification";
import Contract from "./components/Contract";

const rightColumnComponents = {
  paymentDetails: <PaymentDetails />,
  transactionHistory: <TransactionHistory />,
  bankAccount: <BankAccount />,
  creditCard: <CreditCard />,
  notification: <Notification />,
  offerConditions: <OfferConditions />,
  schedule: <Schedule />,
  blockList: <BlockList />,
  mySite: <MySite />,
  emailPassword: <EmailPassword />,
  personalInformation: <PersonalInformation />,
  identityVerification: <IdentityVerification />,
  contract: <Contract />,
};

export default rightColumnComponents;
