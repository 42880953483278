import React from "react";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import styled from "styled-components";

import Button from "../../../components/button/Button";

// Stripe用 ダミーのAPIキー
const stripePromise = loadStripe("pk_test_12345");

// Styled components
const CardPlaceholder = styled.div`
  width: 300px;
  height: 200px;
  background-color: gray;
  border-radius: 4px;
  display: flex;
`;

const CreditCard = () => {
  const cardOptions = {
    style: {
      base: {
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  function PaymentForm() {
    return (
      <Elements stripe={stripePromise}>
        <form>
          <CardNumberElement options={cardOptions} />
          <CardExpiryElement options={cardOptions} />
          <CardCvcElement options={cardOptions} />
        </form>
      </Elements>
    );
  }

  return (
    <div className="u-col u-gap16">
      <p>クレジットカードの説明文</p>
      <PaymentForm />
      <div className="u-w100">
        <div className="heading">クレジットカード</div>
        <div className="u-w100 u-col u-gap8">
          <CardPlaceholder>カード仮置き</CardPlaceholder>
          <CardPlaceholder>カード仮置き</CardPlaceholder>
          <CardPlaceholder>カード仮置き</CardPlaceholder>
        </div>
      </div>
      <Button name="編集" size="medium" color="secondary" />
    </div>
  );
};

export default CreditCard;
