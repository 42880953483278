import React from "react";

const Input = ({
  label = "",
  option = "",
  type = "text",
  autocomplete = "",
  placeholder = "",
  inputmode = "",
  maxlength = "",
  width = "100%",
  append = "",
  alert = "",
  name,
  value,
  onChange,
}) => {
  if (
    !["text", "tel", "email", "number", "date", "search", "time"].includes(type)
  )
    return null;

  const isRequired = option.includes("必須");
  const isOptional = option.includes("任意");
  const labelClass = `label8 u-pl8 ${isRequired ? "u-text-blue" : isOptional ? "u-text-light-gray" : ""}`;
  const containerClass =
    width === "100%" ? "u-col u-w100 u-relative" : "u-col u-fill u-relative";

  return (
    <div className={containerClass}>
      <label>
        {label}
        <span className={labelClass}>{option}</span>
      </label>
      <div className="u-row u-w100">
        <input
          type={type}
          placeholder={placeholder}
          autocomplete={autocomplete}
          inputmode={inputmode}
          maxlength={maxlength}
          style={{ width }}
          className="u-fill"
          name={name}
          value={value}
          onChange={onChange}
        />
        {append && <span className="u-pl8">{append}</span>}
      </div>
      {alert && (
        <p
          className="bodytext-11 u-text-green u-absolute"
          style={{ bottom: "-20px" }}
        >
          {alert}
        </p>
      )}
    </div>
  );
};

export default Input;
