import React from "react";

const SignUpBlock = () => {
  return (
    <section className="signup wrapper">
      <h4>「音」をつけてみる？</h4>
      <a className="button" href="/direct/create_offer">
        今すぐ相談
      </a>
      <p>
        もしくは、
        <a
          href="tel:0364571780"
          style={{
            fontWeight: 300,
            fontSize: "11px",
            letterSpacing: "0.14px",
          }}
        >
          03-6457-1780
        </a>
        へ電話で相談
      </p>
    </section>
  );
};

export default SignUpBlock;
