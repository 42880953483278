import React from "react";

import Input from "../../../components/form-item/Input";

const MySiteState = () => {
  return (
    <div className="u-col u-gap16">
      <p>
        自分の背景や制作の動機、作品や活動に共通するテーマ、これからやりたいことを簡潔に伝えましょう。
      </p>

      <div className="u-col u-w100 c-group">
        <div className="u-w100 u-col u-gap8">
          <Input
            label="アーティストステートメント"
            option="[任意]"
            type="text"
            placeholder="私たちは、あらゆるシーンに寄り添い、そこでしか味わえない音を創造します。"
            width="100%"
            append=""
            name=""
          />
          <Input
            type="text"
            placeholder="We are close to every scene and create sounds that can only be experienced there."
          />
        </div>
      </div>
      <div className="u-col u-w100">
        <div className="u-row u-gap8">
          <input type="checkbox" />
          <div className="heading-13">アバターを表示</div>
        </div>
        <div className="u-row u-gap8">
          <input type="checkbox" />
          <div className="heading-13">アーティスト名を表示</div>
        </div>
        <div className="u-row u-gap8">
          <input type="checkbox" />
          <div className="heading-13">タイトルを表示</div>
        </div>
      </div>
    </div>
  );
};

export default MySiteState;
