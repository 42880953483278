import React from "react";
import styled from "styled-components";

const Heading = styled.div`
  font-weight: bold;
`;

const BlockArea = styled.div`
  width: 100%;
  height: 8rem;
  background-color: gray;
  border-radius: 4px;
  display: flex;
`;

const BlockList = () => {
  return (
    <div className="u-col u-gap16">
      <p>御取引しない会社・ユーザー名を設定ください。</p>
      <div className="u-w100">
        <Heading>ブロックリスト</Heading>
        <BlockArea>ブロックユーザー追加欄仮置き</BlockArea>
      </div>
    </div>
  );
};

export default BlockList;
