export const FOOTER_LINK = [
  {
    link: "/landingPage/contact",
    text: "Contact",
  },
  {
    link: "/updateinfo",
    text: "Update Info",
  },
  {
    link: "https://soremo.notion.site/Owner-Help-Center-76d42490929b46909d60f688413ac9a1",
    text: "Owner Help Center",
  },
  {
    link: "https://soremo.notion.site/Partner-Help-Center-a294ba282ad9438a968a3cb9e1ef7c77",
    text: "Partner Help Center",
  },
  {
    link: "/static/pdf/SOREMO_for_Artist_201111.pdf",
    text: "Join Us",
  },
  {
    link: "/about",
    text: "会社概要",
  },
  {
    link: "#",
    text: "沿革(後で追加)",
  },
  {
    link: "/termsofservice",
    text: "利用規約",
  },
  {
    link: "/tokushoho",
    text: "特定商取引法に基づく表示",
  },
  {
    link: "/privacypolicy",
    text: "プライバシーポリシー",
  },
];
