import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Styled components
const TagContainer = styled.div`
  display: flex;
  flex-wrap: ${({ nowrap }) => (nowrap ? "nowrap" : "wrap")};
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`;

const Tag = styled.span.attrs({
  className: "bodytext-13",
})`
  margin: 4px 8px 4px 0px;
  padding: 4px 12px;
  border-radius: 4px;
  color: var(--soremo-light-gray);
  background-color: var(--soremo-background);
  outline: 1px solid var(--soremo-border);
  user-select: none;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--soremo-border);
    }
  }
`;

const AutoFill = (props) => {
  const { group, nowrap = false, onSelectContent } = props;
  const [keywords, setKeywords] = useState([]);
  const [previousContent, setPreviousContent] = useState(null);

  useEffect(() => {
    const fetchKeywords = () => {
      // データオブジェクトを直接インポート
      import(`./${group}`).then((module) => {
        const dataArray = module[group]; //js内のデータ配列を取得

        if (Array.isArray(dataArray)) {
          // オブジェクトのリストからkeywordを抽出
          const allKeywords = dataArray.map((item) => item.keyword);
          setKeywords(allKeywords);
        } else {
          setKeywords([]);
        }
      });
    };
    fetchKeywords();
  }, [group]);

  const handleKeywordClick = (keyword) => {
    import(`./${group}`).then((module) => {
      const dataArray = module[group];
      const matchedItem = dataArray.find((item) => item.keyword === keyword);

      if (matchedItem && Array.isArray(matchedItem.contents)) {
        let availableContents = matchedItem.contents;

        // データが複数ある場合、前回のデータを除外
        if (availableContents.length > 1 && previousContent !== null) {
          availableContents = availableContents.filter(
            (content) => content !== previousContent,
          );
        }

        // ランダムに一つのデータを選択
        const randomContent =
          availableContents[
            Math.floor(Math.random() * availableContents.length)
          ];

        // 前回選択したデータとして保存
        setPreviousContent(randomContent);

        // 親コンポーネントのコールバック関数を呼び出し、選択したデータを渡す
        if (onSelectContent) {
          onSelectContent(randomContent);
        }
      }
    });
  };

  return (
    <TagContainer nowrap={nowrap}>
      {keywords.map((keyword, index) => (
        <Tag key={index} onClick={() => handleKeywordClick(keyword)}>
          {keyword}
        </Tag>
      ))}
    </TagContainer>
  );
};

export default AutoFill;
