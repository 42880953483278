import React, { useState, useEffect } from "react";
import styled from "styled-components";

import ListItem from "../../../components/list-item/ListItem";
import Button from "../../../components/button/Button";
import NavigateBefore from "../../../components/navigate/NavigateBefore";
import MySiteHeader from "./MySiteHeader";
import MySiteState from "./MySiteState";
import MySiteChapter from "./MySiteChapter";
import MySiteTopic from "./MySiteTopic";
import MySiteWorks from "./MySiteWorks";
import MySiteFooter from "./MySiteFooter";

const MySite = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [showRightColumn, setShowRightColumn] = useState(false);
  const RightColumn = styled.div`
    width: min(100% - 32px, 756px);
    // ↓241028 両端に16pxずつあったpaddingを消しています。
    padding: 40px 0px;
    margin-inline: auto;
  `;

  useEffect(() => {
    const handleResize = () => {
      // setIsMobile(window.innerWidth < 640);
      setShowRightColumn(!(window.innerWidth < 640));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="u-col u-gap16">
      <p>マイサイトを自分らしくカスタマイズしましょう。</p>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          固有のURLを設定し、QRコードを生成できます。
        </div>
        <div className="c-group u-w100">
          <div className="u-row u-gap4">
            <div className="heading">URL</div>
            <span className="label8 u-text-light-gray">[任意]</span>
          </div>
          <div className="u-col u-gap8 u-w100">
            <div className="u-row u-w100">
              <div
                className="bodytext-11 u-col-center u-text-right u-text-light-gray u-bg-border"
                style={{
                  width: "auto",
                  height: "48px",
                  paddingLeft: "16px",
                  paddingRight: "4px",
                  borderRadius: "4px 0 0 4px",
                }}
              >
                https://soremo.jp/
              </div>
              <input
                type="text"
                id="profile_url"
                name="profile_url"
                defaultValue=""
                placeholder=""
                maxLength={60}
                className=""
                style={{ height: "48px", borderRadius: "0 4px 4px 0" }}
              />
            </div>
            <Button name="コピー" size="large" color="secondary" />
          </div>
        </div>
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">マイサイトの公開範囲を選択できます。</div>
        <div className="c-group u-w100">
          <div className="u-row u-gap4">
            <div className="heading">公開範囲</div>
            <span className="label8 u-text-blue">[必須]</span>
          </div>
          <div className="u-w100 u-col u-gap4 c-radio">
            <div className="u-col">
              <div className="u-row u-gap8">
                <input
                  type="radio"
                  name="visibility"
                  id="open"
                  defaultValue="open"
                />
                <label htmlFor="immediately" className="heading-13">
                  オープン
                </label>
              </div>
              <div className="bodytext-11">
                ※誰でもアクセスできます。オーナー（お客様）と直接取引したり、作品販売もできます。
              </div>
            </div>
            <div className="u-col">
              <div className="u-row u-gap8">
                <input
                  type="radio"
                  name="visibility"
                  id="only_members"
                  defaultValue="only_members"
                />
                <label htmlFor="one_day" className="heading-13">
                  メンバーズオンリー
                </label>
              </div>
              <div className="bodytext-11">
                ※SOREMOアカウント保有のメンバーのみアクセスできます。
              </div>
            </div>
            <div className="u-col">
              <div className="u-row u-gap8">
                <input
                  type="radio"
                  name="visibility"
                  id="only_project"
                  defaultValue="only_project"
                  defaultChecked="checked"
                />
                <label htmlFor="off" className="heading-13">
                  プロジェクトオンリー
                </label>
              </div>
              <div className="bodytext-11">
                ※参加したプロジェクトのスタッフクレジット経由のみアクセスできます。
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          マイサイトでのお問い合わせ対応方法を選択できます。
        </div>

        <div className="c-group u-w100">
          <div className="u-row u-gap4">
            <div className="heading">コンタクト方法</div>
            <span className="label8 u-text-blue">[必須]</span>
          </div>
          <div className="u-w100" style={{ overflowX: "scroll" }}>
            <div className="u-w100 u-row u-gap16">
              <label className="c-radio-icon" htmlFor="contact-01">
                <div
                  className=""
                  style={{
                    background:
                      '#ffffff url("images/contact_01.png") no-repeat center / contain',
                    width: "180px",
                    aspectRatio: 1 / 2,
                  }}
                ></div>
                <input
                  type="radio"
                  name="contact"
                  id="contact-01"
                  defaultChecked="checked"
                />
              </label>
              <label className="c-radio-icon" htmlFor="contact-02">
                <div
                  className=""
                  style={{
                    background:
                      '#ffffff url("images/contact_02.png") no-repeat fixed center / contain',
                    width: "180px",
                    aspectRatio: 1 / 2,
                  }}
                ></div>
                <input type="radio" name="contact" id="contact-02" />
              </label>
              <label className="c-radio-icon" htmlFor="contact-03">
                <div
                  className=""
                  style={{
                    background:
                      '#ffffff url("images/contact_01.png") no-repeat fixed center / contain',
                    width: "180px",
                    aspectRatio: 1 / 2,
                  }}
                ></div>
                <input type="radio" name="contact" id="contact-03" />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="u-w100">
        <ListItem
          name="ヘッダーデザイン"
          onClick={() => {
            setSelectedItem("mySiteHeader");
            setShowRightColumn(true);
          }}
        />
        <ListItem
          name="アーティストステートメント"
          onClick={() => {
            setSelectedItem("mySiteState");
            setShowRightColumn(true);
          }}
        />
        <ListItem
          name="トピック"
          onClick={() => {
            setSelectedItem("mySiteTopic");
            setShowRightColumn(true);
          }}
        />
        <ListItem
          name="作品登録"
          onClick={() => {
            setSelectedItem("mySiteWorks");
            setShowRightColumn(true);
          }}
        />
        <ListItem
          name="チャプター"
          onClick={() => {
            setSelectedItem("mySiteChapter");
            setShowRightColumn(true);
          }}
        />
        <ListItem
          name="フッターデザイン"
          onClick={() => {
            setSelectedItem("mySiteFooter");
            setShowRightColumn(true);
          }}
        />
      </div>

      {showRightColumn && (
        <>
          <NavigateBefore onClick={() => setShowRightColumn(false)} />
          <RightColumn>
            {selectedItem === "mySiteHeader" && <MySiteHeader />}
            {selectedItem === "mySiteState" && <MySiteState />}
            {selectedItem === "mySiteTopic" && <MySiteTopic />}
            {selectedItem === "mySiteWorks" && <MySiteWorks />}
            {selectedItem === "mySiteChapter" && <MySiteChapter />}
            {selectedItem === "mySiteFooter" && <MySiteFooter />}
          </RightColumn>
        </>
      )}
    </div>
  );
};

export default MySite;
