import React from "react";
import styled from "styled-components";

const PlaceholderBox = styled.div`
  width: 100%;
  height: 12rem;
  margin-top: 16px;
  background-color: gray;
  border-radius: 4px;
`;

const Schedule = () => {
  return (
    <div className="u-col u-gap16">
      <p>あなたの予定を伝えて、期日を配慮してもらいましょう。</p>
      <div className="u-w100">
        <div className="heading">スケジュール</div>
        <textarea
          rows={5}
          placeholder="（例）水曜日は、休日にしています。土曜日は、できれば仕事をしたいです。"
          defaultValue=""
        />
        <PlaceholderBox>カレンダー用仮置き</PlaceholderBox>
      </div>
    </div>
  );
};

export default Schedule;
