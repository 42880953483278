import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { useGetProfileByNameQuery } from "../../../app/services/auth";
import { useGetProjectQuery } from "../../../app/services/project";
import ProjectBanner from "./components/ProjectBanner";
import "./style.scss";
import ProjectItemDetail from "./components/project-detail";

const ProjectPageLayout = () => {
  const { projectId } = useParams(); // Access the projectId
  const [modalMemberVisible, setModalMemberVisible] = useState(false);
  const { data: userInfo } = useGetProfileByNameQuery("");
  let {
    data: project,
    // isLoading: loading,
    // isFetching,
  } = useGetProjectQuery(projectId);

  const user = userInfo?.result;

  return (
    <main
      className={`owner-top prdt ${isMobile ? "on-mobile" : ""} ${user?.role === "admin" ? "is-admin" : ""}`}
      data-title-page={project?.get_name_in_page_title}
      data-start-date={dayjs(project?.start_time).format("YYYY-MM-DD")}
      data-end-date={dayjs(project?.end_time).format("YYYY-MM-DD")}
      id="main-container"
    >
      <div className="container">
        <div className="new-video-menu">
          <div className="project-list">
            <div
              className={`project-item active ${isMobile ? "on-mobile" : ""}`}
            >
              {project ? (
                <ProjectBanner
                  project={project}
                  user={user}
                  modalMember={modalMemberVisible}
                  setModalMember={setModalMemberVisible}
                />
              ) : (
                <></>
              )}
              {project ? (
                <ProjectItemDetail
                  project={project}
                  user={user}
                  productScenes={project?.product_scene_list || []}
                  viewOnly={false}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProjectPageLayout;
