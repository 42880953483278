import React from "react";

import Input from "../../../components/form-item/Input";

const MySiteChapter = () => {
  return (
    <div className="u-col u-gap16">
      <p>チャプター説明文</p>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          ABOUT、PROFILEなどの表題を決めましょう。
        </div>
        <div className="c-group u-w100">
          <div className="u-w100 u-row u-gap16">
            <Input
              label="チャプター"
              option="[任意]"
              type="text"
              placeholder="プロフィール"
              width="100%"
              append=""
              name=""
            />
            <Input
              type="text"
              placeholder="Profile"
              width="100%"
              append=""
              name=""
            />
          </div>
          <div className="u-row u-gap8">
            <input type="checkbox" />
            <div className="heading-13">トップメニューにリンク</div>
          </div>
        </div>
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          プロフィールなどを自由に記載しましょう。
        </div>
        <div className="c-group u-w100">
          <Input
            label="テキスト"
            option="[任意]"
            type="text"
            placeholder="プロフィール"
            width="100%"
            append=""
            name=""
          />
          <Input
            type="text"
            placeholder="Profile"
            width="100%"
            append=""
            name=""
          />
        </div>
      </div>
    </div>
  );
};

export default MySiteChapter;
