// RadioCardコンポーネント
import React from "react";
import styled from "styled-components";

function RadioCard(props) {
  const {
    name,
    id,
    label,
    checked = false,
    width = "128px",
    height = "256px",
    showImage,
    showRadio,
    onClick,
  } = props;

  return (
    <Card height={height} checked={checked} onClick={onClick}>
      {showRadio && checked ? (
        <StyledRadio type="radio" name="radio-card" checked>
          radio_button_checked
        </StyledRadio>
      ) : showRadio && !checked ? (
        <StyledRadio type="radio" name="radio-card">
          radio_button_unchecked
        </StyledRadio>
      ) : (
        <></>
      )}
      <StyledLabel width={width}>{label}</StyledLabel>
      {showImage && (
        <GuideImage width={width} height={height} name={name} id={id} />
      )}
    </Card>
  );
}

export default RadioCard;

// Styled-Components
const Card = styled.div.attrs({
  className: "u-row",
})`
  position: relative;
  width: 100%;
  height: ${({ height }) => height};
  padding: 16px 12px 16px;

  border-radius: 6px;
  border: ${({ checked }) =>
    checked
      ? "1px solid var(--soremo-blue)"
      : "1px solid var(--soremo-border)"};
  outline: ${({ checked }) =>
    checked
      ? "1px solid var(--soremo-blue)"
      : "1px solid var(--soremo-border)"};
  background-color: #fff;

  /* Card drop shadow */
  box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.05);

  &:hover {
    border: 1px solid var(--soremo-blue);
    outline: 1px solid var(--soremo-blue);
    box-shadow: 0px 0px 6px 0px var(--soremo-blue);
    cursor: pointer;
  }
`;

const StyledRadio = styled.div.attrs({
  className: "u-absolute material-symbols-rounded",
})`
  top: 8px;
  right: 8px;
  color: ${({ checked }) =>
    checked ? "var(--soremo-blue)" : "var(--soremo-border)"};
`;

const StyledLabel = styled.label`
  padding-left: ${({ width }) => width};
`;

const GuideImage = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;

  width: calc(${({ width }) => width} - 2px);
  height: calc(${({ height }) => height} - 2px);

  border-radius: 6px 0px 0px 6px;
  background-image: url(${({ name, id }) =>
    require(`../../assets/images/card_${name}_${id}.png`)});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
