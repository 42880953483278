import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {},
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      //set value in payload to state
      state.value = action.payload;
    },
    unsetProfile: (state) => {
      //remove profile value
      state.value = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProfile, unsetProfile } = profileSlice.actions;

export default profileSlice.reducer;
