import React, { useState, useEffect } from "react";
import { Rnd } from "react-rnd";
import styled from "styled-components";

import ListItem from "../../components/list-item/ListItem";
// import BottomAppBar from "../../components/bottom-app-bar/BottomAppBar";
import NavigateBefore from "../../components/navigate/NavigateBefore";
import UserItem from "../../components/user-item/UserItem";
import MileageBlock from "./components/MileageBlock";
import TransactionStatus from "./components/TransactionStatus";
import leftColumnList from "./leftColumnList"; // Adjust the path as needed
import rightColumnList from "./rightColumnList"; // Adjust the path as needed

// Styled components
const MyPageContainer = styled.div`
  width: 100%;
  height: calc(100vh + 160px);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: start;

  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const LeftColumn = styled.div`
  width: 100%;
  position: relative;

  @media (min-width: 640px) {
    width: clamp(320px, 38.2vw, 1140px * 0.382);
  }
`;

const RightColumn = styled.div.attrs({
  className: "u-wrapper-reading-paragraph",
})`
  // padding: 40px 0;
  margin-inline: auto;
`;

const MyPage = ({ user }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [showRightColumn, setShowRightColumn] = useState(false);
  const [leftColumnWidth, setLeftColumnWidth] = useState(
    "clamp(320px, 38.2vw, calc(1140px * 0.382))",
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
      setShowRightColumn(!(window.innerWidth < 640));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderListItems = (items) => {
    return items.map((item) => (
      <ListItem
        key={item.name}
        name={item.name}
        status={item.status}
        alert={item.alert}
        onClick={item.onClick}
        size={item.size}
      />
    ));
  };

  return (
    <>
      <MyPageContainer>
        {isMobile ? (
          <>
            {!showRightColumn && (
              <LeftColumn style={{ overflowY: "none" }}>
                {selectedItem === null && (
                  <>
                    <UserItem user={user} />
                    <MileageBlock />
                    <TransactionStatus />
                  </>
                )}
                {renderListItems(
                  leftColumnList({ setSelectedItem, setShowRightColumn }),
                )}
              </LeftColumn>
            )}
            {showRightColumn && (
              <RightColumn>
                <NavigateBefore onClick={() => setShowRightColumn(false)} />
                {rightColumnList[selectedItem]}
              </RightColumn>
            )}
          </>
        ) : (
          <>
            <Rnd
              minWidth={320}
              maxWidth={640}
              enableResizing={{ right: true }}
              disableDragging={true}
              onResize={(e, direction, ref) => {
                setLeftColumnWidth(ref.offsetWidth);
              }}
              style={{ position: "relative" }}
            >
              <LeftColumn style={{ width: leftColumnWidth, overflowY: "none" }}>
                <div className="resize-handle"></div>
                {renderListItems(
                  leftColumnList({ setSelectedItem, setShowRightColumn }),
                )}
              </LeftColumn>
            </Rnd>
            <RightColumn>
              {selectedItem === null && (
                <>
                  <UserItem user={user} />
                  <MileageBlock />
                  <TransactionStatus />
                </>
              )}
              {rightColumnList[selectedItem]}
            </RightColumn>
          </>
        )}
      </MyPageContainer>
      {/* <BottomAppBar /> */}
    </>
  );
};

export default MyPage;
