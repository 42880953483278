import React from "react";

import Button from "../../../components/button/Button";
import GuideImage from "../../../components/guide-image/GuideImage";
import Input from "../../../components/form-item/Input";

// Styled components

const EmailPassword = () => {
  return (
    <div className="u-col u-gap16">
      <GuideImage name="account" />
      <p>サインインに必要なメールアドレスとパスワードを変更できます。</p>
      <div className="u-w100">
        <Input
          label="メールアドレス"
          option="[必須]"
          type="email"
          name="email"
          inputmode="email"
          placeholder="username@soremo.jp"
        />
      </div>
      <Button name="パスワード変更" size="medium" color="secondary" />
    </div>
  );
};

export default EmailPassword;
