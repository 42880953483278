import React from "react";
import styled from "styled-components";

const AvatarContainer = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 43% 57% 43% 57% / 57% 43% 57% 43%;
  transform: rotate(-19deg);
  border: 1px solid #fff;
  box-shadow: 2px 4px 10px 0px #e5e5e5;
  overflow: hidden;
  position: relative;
`;

const AvatarImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transform: rotate(19deg);
`;

const OnlineBadge = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  background-color: #2cc84d;
  border: 1px solid #fff;
  border-radius: 50%;
  z-index: 1;
`;

const Avatar = ({ size = 32, src, isOnline = false }) => {
  return (
    <div style={{ position: "relative" }}>
      {isOnline && <OnlineBadge />}
      <AvatarContainer size={size}>
        <AvatarImage src={src} />
      </AvatarContainer>
    </div>
  );
};

export default Avatar;
