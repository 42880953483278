import React, { useState, useEffect } from "react";

import Input from "../../../components/form-item/Input";
import Button from "../../../components/button/Button";

const PersonalInformation = () => {
  // State to manage form data
  const [personalData, setPersonalData] = useState({
    fullName: "",
    birth: "",
    phone: "",
    postalCode: "",
    prefectures: "",
    address1: "",
    address2: "",
    address3: "",
    invoice: "",
  });

  // State to manage validation errors
  const [errors, setErrors] = useState({});

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    // 郵便番号の自動ハイフン挿入処理
    if (name === "postalCode") {
      // 数字以外を除去
      newValue = newValue.replace(/\D/g, "");
      // 7桁以上はカット
      if (newValue.length > 7) {
        newValue = newValue.slice(0, 7);
      }
      // 3桁目の後にハイフンを挿入
      if (newValue.length > 3) {
        newValue = newValue.slice(0, 3) + "-" + newValue.slice(3);
      }
    }

    // Update form data
    const updatedData = {
      ...personalData,
      [name]: newValue,
    };

    setPersonalData(updatedData);
    setErrors(validateForm(updatedData)); // Validate after update
  };

  // Validate the form inputs
  const validateForm = (data) => {
    const newErrors = {};
    // if (!data.fullName) newErrors.fullName = "氏名は必須項目です。";
    // if (!data.birth) newErrors.birth = "生年月日を入力してください。";
    // if (!data.phone) newErrors.phone = "電話番号を入力してください。";
    // if (data.phone && !/^\d+$/.test(data.phone)) newErrors.phone = "数字で入力してください。";
    if (
      data.phone &&
      !/^(0[5-9]0[-(]?[0-9]{4}[-)]?[0-9]{4}|0120[-]?\d{1,3}[-]?\d{4}|050[-]?\d{4}[-]?\d{4}|0[1-9][-]?\d{1,4}[-]?\d{1,4}[-]?\d{4})*$/.test(
        data.phone,
      )
    )
      newErrors.phone = "正しい形式で入力してください。";
    // if (!/^[0-9]{3}-?[0-9]{4}$/.test(data.postalCode)) newErrors.postalCode = "7桁（xxx-yyyyもしくはxxxyyyy）で入力してください。";
    if (data.postalCode && !/^[0-9]{3}-?[0-9]{4}$/.test(data.postalCode))
      newErrors.postalCode = "7桁の数字で入力してください。";
    // if (!data.prefectures) newErrors.prefectures = "都道府県を入力してください。";
    // if (!data.address1) newErrors.address1 = "市区町村を入力してください。";
    // if (!data.address2) newErrors.address2 = "番地以降を入力してください。";
    // if (!data.address3) newErrors.address2 = "建物名・部屋番号を入力してください。";
    if (data.invoice && !/^T\d{13}$/.test(data.invoice))
      newErrors.invoice = "T+13桁の数字で入力してください。";

    return newErrors;
  };

  // Run validation on initial render
  useEffect(() => {
    setErrors(validateForm(personalData));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="u-col u-gap32">
      <p>
        個人情報は、外部には公開されません。管理方法については、こちらをご確認ください
      </p>
      <Input
        type="text"
        label="氏名"
        option="[必須]"
        placeholder="例：山田 太郎"
        name="fullName"
        autocomplete="name"
        alert={errors.fullName}
        value={personalData.fullName}
        onChange={handleChange}
      />
      <Input
        type="date"
        label="生年月日"
        option="[任意]"
        placeholder=""
        name="birth"
        alert={errors.birth}
        value={personalData.birth}
        onChange={handleChange}
      />
      <Input
        type="tel"
        label="電話番号"
        option="[任意]"
        placeholder="03-6457-1780"
        name="phone"
        autocomplete="tel"
        alert={errors.phone}
        value={personalData.phone}
        onChange={handleChange}
      />
      <div className="u-col u-gap16 u-w100">
        <div className="u-row u-items-end u-gap16 u-w100">
          <Input
            type="text"
            label="郵便番号"
            option="[任意]"
            placeholder="135-0064"
            width="128px"
            name="postalCode"
            autocomplete="postal-code"
            alert={errors.postalCode}
            value={personalData.postalCode}
            onChange={handleChange}
          />
          <Button name="住所検索" size="medium" color="secondary" />
        </div>
        <div className="u-row u-gap16 u-w100">
          <Input
            type="text"
            label="都道府県"
            option="[任意]"
            placeholder="東京都"
            width="96px"
            name="prefectures"
            autocomplete="address-level1"
            alert={errors.prefectures}
            value={personalData.prefectures}
            onChange={handleChange}
          />
          <Input
            type="text"
            label="市区町村"
            option="[任意]"
            placeholder="江東区"
            name="address1"
            autocomplete="address-level2"
            alert={errors.address1}
            value={personalData.address1}
            onChange={handleChange}
          />
        </div>
        <Input
          type="text"
          label="番地"
          option="[任意]"
          placeholder="青海二丁目4-7"
          name="address2"
          alert={errors.address2}
          value={personalData.address2}
          onChange={handleChange}
        />
        <Input
          type="text"
          label="建物名・部屋番号"
          option="[任意]"
          placeholder="theSOHO1226"
          name="address3"
          alert={errors.address3}
          value={personalData.address3}
          onChange={handleChange}
        />
      </div>
      <Input
        type="text"
        label="適格請求書発行事業者登録番号"
        option="[任意]"
        placeholder="T5010601036833"
        name="invoice"
        alert={errors.invoice}
        value={personalData.invoice}
        onChange={handleChange}
      />
    </div>
  );
};

export default PersonalInformation;
