import React from "react";
import styled from "styled-components";

const Heading = styled.div`
  font-weight: bold;
`;

const PdfArea = styled.div`
  width: 100%;
  height: 30rem;
  background-color: gray;
  border-radius: 4px;
  display: flex;
`;

const Contract = () => {
  return (
    <div className="u-col u-gap16">
      <p>株式会社SOREMOとの契約締結がお済みの場合、閲覧できます。</p>
      <div className="u-w100">
        <Heading>機密保持契約書</Heading>
        <PdfArea>PDF閲覧用エリア仮置き</PdfArea>
      </div>
    </div>
  );
};

export default Contract;
