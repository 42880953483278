import React from "react";

const TransactionHistory = () => {
  return (
    <div className="u-col u-gap16">
      <p>プロジェクトごとに取引履歴を閲覧できます。</p>
      <div className="u-w100">
        <div className="heading">取引履歴</div>

        <div className="u-w100 u-col u-gap16">
          {/* プロジェクトごとに表示 */}
          <div className="u-w100">
            <div className="heading-13">プロジェクトABC</div>
            <div className="u-w100 c-group" style={{ overflowX: "scroll" }}>
              <table
                className="u-w100"
                style={{ whiteSpace: "nowrap", tableLayout: "fixed" }}
              >
                <thead
                  className="bodytext-13 u-text-right-gray"
                  style={{ textAlign: "left" }}
                >
                  <tr>
                    <th style={{ width: "128px" }}>相手先</th>
                    <th style={{ width: "128px" }}>金額（税込）</th>
                    <th style={{ width: "96px" }}>取引成立日</th>
                    <th style={{ width: "96px" }}>検収日</th>
                    <th style={{ width: "64px" }}>シーン</th>
                    <th style={{ width: "64px" }}>ロール</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="bodytext-13">ナマエナマエナマエ</div>
                    </td>
                    <td>
                      <div className="bodytext-13">10,000,000,000円</div>
                    </td>
                    <td>
                      <div className="bodytext-13">24/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">00/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">scene</div>
                    </td>
                    <td>
                      <div className="bodytext-13">role</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="bodytext-13">ナマエ</div>
                    </td>
                    <td>
                      <div className="bodytext-13">100,000円</div>
                    </td>
                    <td>
                      <div className="bodytext-13">24/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">00/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">scene</div>
                    </td>
                    <td>
                      <div className="bodytext-13">role</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="u-w100">
            <div className="heading-13">プロジェクトDEF</div>
            <div className="u-w100 c-group" style={{ overflowX: "scroll" }}>
              <table
                className="u-w100"
                style={{ whiteSpace: "nowrap", tableLayout: "fixed" }}
              >
                <thead
                  className="bodytext-13 u-text-right-gray"
                  style={{ textAlign: "left" }}
                >
                  <tr>
                    <th style={{ width: "128px" }}>相手先</th>
                    <th style={{ width: "128px" }}>金額（税込）</th>
                    <th style={{ width: "96px" }}>取引成立日</th>
                    <th style={{ width: "96px" }}>検収日</th>
                    <th style={{ width: "64px" }}>シーン</th>
                    <th style={{ width: "64px" }}>ロール</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="bodytext-13">ナマエナマエナマエ</div>
                    </td>
                    <td>
                      <div className="bodytext-13">10,000,000,000円</div>
                    </td>
                    <td>
                      <div className="bodytext-13">24/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">00/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">scene</div>
                    </td>
                    <td>
                      <div className="bodytext-13">role</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="bodytext-13">ナマエ</div>
                    </td>
                    <td>
                      <div className="bodytext-13">100,000円</div>
                    </td>
                    <td>
                      <div className="bodytext-13">24/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">00/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">scene</div>
                    </td>
                    <td>
                      <div className="bodytext-13">role</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="bodytext-13">ナマエナマエナマエ</div>
                    </td>
                    <td>
                      <div className="bodytext-13">10,000,000,000円</div>
                    </td>
                    <td>
                      <div className="bodytext-13">24/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">00/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">scene</div>
                    </td>
                    <td>
                      <div className="bodytext-13">role</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="u-w100">
            <div className="heading-13">プロジェクトGHI</div>
            <div className="u-w100 c-group" style={{ overflowX: "scroll" }}>
              <table
                className="u-w100"
                style={{ whiteSpace: "nowrap", tableLayout: "fixed" }}
              >
                <thead
                  className="bodytext-13 u-text-right-gray"
                  style={{ textAlign: "left" }}
                >
                  <tr>
                    <th style={{ width: "128px" }}>相手先</th>
                    <th style={{ width: "128px" }}>金額（税込）</th>
                    <th style={{ width: "96px" }}>取引成立日</th>
                    <th style={{ width: "96px" }}>検収日</th>
                    <th style={{ width: "64px" }}>シーン</th>
                    <th style={{ width: "64px" }}>ロール</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="bodytext-13">ナマエナマエナマエ</div>
                    </td>
                    <td>
                      <div className="bodytext-13">10,000,000,000円</div>
                    </td>
                    <td>
                      <div className="bodytext-13">24/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">00/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">scene</div>
                    </td>
                    <td>
                      <div className="bodytext-13">role</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="bodytext-13">ナマエ</div>
                    </td>
                    <td>
                      <div className="bodytext-13">100,000円</div>
                    </td>
                    <td>
                      <div className="bodytext-13">24/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">00/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">scene</div>
                    </td>
                    <td>
                      <div className="bodytext-13">role</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="bodytext-13">ナマエナマエナマエ</div>
                    </td>
                    <td>
                      <div className="bodytext-13">10,000,000,000円</div>
                    </td>
                    <td>
                      <div className="bodytext-13">24/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">00/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">scene</div>
                    </td>
                    <td>
                      <div className="bodytext-13">role</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="bodytext-13">ナマエナマエナマエ</div>
                    </td>
                    <td>
                      <div className="bodytext-13">10,000,000,000円</div>
                    </td>
                    <td>
                      <div className="bodytext-13">24/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">00/00/00</div>
                    </td>
                    <td>
                      <div className="bodytext-13">scene</div>
                    </td>
                    <td>
                      <div className="bodytext-13">role</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
