import { Link } from "react-router-dom";
import styled from "styled-components";

import Avatar from "../avatar/Avatar";
const StatusLabel = styled.li`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #fcfcfc;
  border: 1px solid #f0f0f0;
  color: #000;
`;

const UserItem = ({ size, editable = false }) => {
  // Test data
  const user = {
    stage_name: "本山 聖陽",
    position: "プロデューサー",
    stage_name_en: "Masaaki Motoyama",
    enterprise: "TEAM Synergy",
    company_url: "https://soremo.jp",
  };

  return (
    <div className="u-col-center u-gap16" style={{ padding: "32px 0px 40px" }}>
      <Avatar size="96" />
      <ul className="u-col u-gap4">
        <li className="heading-18-spacing">{user.stage_name}</li>
        <li className="bodytext-13 u-line-height-200">{user.position}</li>
        <Link to={user.company_url}>
          <li className="u-line-height-100 bodytext-11 u-text-light-gray">
            {user.enterprise}
          </li>
        </Link>
      </ul>
      <div className="u-row u-gap8 u-pt8">
        <StatusLabel className="bodytext-11">本人確認済み</StatusLabel>
        <StatusLabel className="bodytext-11">NDA締結済み</StatusLabel>
      </div>
    </div>
  );
};

export default UserItem;
