import React from "react";

import Input from "../../../components/form-item/Input";

const MySiteFooter = () => {
  return (
    <div className="u-col u-gap16">
      <p>フッターに表示するコンテンツを設定しましょう。</p>

      <div className="u-col u-w100">
        <div className="bodytext-11">リンク先を設定できます。</div>
        <div className="u-row u-gap4">
          <div className="heading">メニュー</div>
          <span className="label8 u-text-light-gray">[任意]</span>
        </div>
        <div
          style={{
            width: "100%",
            height: "4rem",
            backgroundColor: "gray",
            borderRadius: 4,
          }}
        >
          「メニューを追加」欄
        </div>
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">アイコンとリンク先を設定できます。</div>
        <div className="u-row u-gap4">
          <div className="heading">アイコン</div>
          <span className="label8 u-text-light-gray">[任意]</span>
        </div>
        <div
          style={{
            width: "100%",
            height: "4rem",
            backgroundColor: "gray",
            borderRadius: 4,
          }}
        >
          「アイコンリンクを追加」欄
        </div>
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">権利表記を調整できます。</div>
        <Input
          label="コピーライト"
          option="[任意]"
          type="text"
          placeholder="&copy;SOREMO Co., ltd. All right reserved."
          width="100%"
          append=""
          name=""
        />
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          コピーライトに続くサブメニューを追加できます。
        </div>
        <div className="u-row u-gap4">
          <div className="heading">サブメニュー</div>
          <span className="label8 u-text-light-gray">[任意]</span>
        </div>
        <div
          style={{
            width: "100%",
            height: "4rem",
            backgroundColor: "gray",
            borderRadius: 4,
          }}
        >
          「サブメニューを追加」欄
        </div>
      </div>
    </div>
  );
};

export default MySiteFooter;
