import React from "react";
import styled from "styled-components";

// import Button from "../../../components/button/Button";

const TransactionStatusStyle = styled.dl`
  border-radius: 4px;
  border: 1px solid var(--soremo-border);
  background-color: #009ace;
  color: #fff;
  padding: 16px 0px 16px 12px;
  margin: 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TransactionStatus = () => {
  return (
    <TransactionStatusStyle>
      <ul>
        <li className="bodytext-13">残高</li>
        <ul className="u-row-between">
          <li className="heading-32 u-line-height-150">
            10,000,000<span className="bodytext-11 u-pl4">円（税込）</span>
          </li>
          <ul className="u-row u-gap4">
            {/* <li>振込申請</li> */}
            <li className="material-symbols-rounded u-text-white">
              chevron_right
            </li>
          </ul>
          {/* <Button name="振込申請" size="medium" rightIcon="chevron_right" /> */}
        </ul>
      </ul>
      <ul>
        <li className="bodytext-13">仕掛</li>
        <ul className="u-row-between">
          <li className="heading-16 u-line-height-150">
            100,000<span className="bodytext-11 u-pl4">円（税込）</span>
          </li>
          <ul className="u-row u-gap4">
            {/* <li>進行中の取引</li> */}
            <li className="material-symbols-rounded u-text-white">
              chevron_right
            </li>
          </ul>
        </ul>
      </ul>
    </TransactionStatusStyle>
  );
};

export default TransactionStatus;
