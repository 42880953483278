import "./App.scss";
import "./soremo_style.scss";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import routeSchema from "./pages/routes";
import GlobalWraper from "./components/global/GlobalWraper";

const Application = () => {
  return (
    <>
      <Router>
        <Routes>
          {routeSchema.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <GlobalWraper
                  isHideTopBar={route?.isHideTopBar}
                  children={route.component}
                />
              }
              exact={route.exact}
            />
          ))}
        </Routes>
      </Router>
    </>
  );
};

export default Application;
