import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Button from "../../button/Button";
import { linkList } from "../../../constant/links";

const GlobalMenu = styled.div`
  height: 64px;
  position: sticky;
  transition: top 0.2s;
  top: 0;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% - 32px);
  margin-inline: auto;
`;

const UnauthorizedHeader = () => {
  return (
    <GlobalMenu>
      <Link to={linkList.account.accounts_login}>
        <Button name="サインイン" size="small" />
      </Link>
    </GlobalMenu>
  );
};

export default UnauthorizedHeader;
