import React, { useState, useEffect } from "react";

import Input from "../../../components/form-item/Input";
import Select from "../../../components/form-item/Select";

const BankAccount = () => {
  // State to manage form data
  const [bankData, setBankData] = useState({
    bank: "",
    bankBranch: "",
    bankBranchNumber: "",
    accountType: "normal", // Set default account type
    accountNumber: "",
    accountName: "",
  });

  // State to manage validation errors
  const [errors, setErrors] = useState({});

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Update form data
    const updatedData = {
      ...bankData,
      [name]: value,
    };

    setBankData(updatedData);
    setErrors(validateForm(updatedData)); // Validate after update
  };

  // Validate the form inputs
  const validateForm = (data) => {
    const newErrors = {};
    // if (!data.bank) newErrors.bank = "せめて銀行をいれておくれ..";
    // if (!data.bankBranch) newErrors.bankBranch = "支店名を入力してください。";
    if (data.bankBranchNumber && !/^\d{3}$/.test(data.bankBranchNumber))
      newErrors.bankBranchNumber = "3桁の数字でください";
    if (data.accountNumber) {
      if (!/^\d{7}$|^\d{10}$/.test(data.accountNumber)) {
        newErrors.accountNumber = "7桁もしくは10桁の数字でください";
      }
    }
    // if (!data.accountName)
    //   newErrors.accountName = "口座名義を入力してください。";
    return newErrors;
  };

  // Run validation on initial render
  useEffect(() => {
    setErrors(validateForm(bankData));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <p className="u-mb16">残高を出金するための銀行口座を登録してください。</p>
      <div className="u-col u-gap24 u-w100">
        <Input
          label="銀行名"
          option="[任意]"
          type="text"
          placeholder="楽天"
          width="100%"
          append="銀行"
          name="bank"
          alert={errors.bank}
          value={bankData.bank}
          onChange={handleChange}
        />
        <div className="u-row u-gap16 u-w100">
          <Input
            label="支店"
            option="[任意]"
            type="text"
            placeholder="青海"
            append="支店"
            name="bankBranch"
            alert={errors.bankBranch}
            value={bankData.bankBranch}
            onChange={handleChange}
          />
          <Input
            label="支店番号"
            option="[任意]"
            type="text"
            placeholder="123"
            name="bankBranchNumber"
            maxlength="3"
            inputmode="numeric"
            alert={errors.bankBranchNumber}
            value={bankData.bankBranchNumber}
            onChange={handleChange}
          />
        </div>
        <div className="u-row u-gap16 u-w100">
          <Select
            defaultValue={bankData?.accountType}
            label="口座種別"
            options={[
              { value: "normal", name: "普通" },
              { value: "temp", name: "当座" },
              { value: "reverse_tax", name: "納税準備預金" },
              { value: "saving", name: "貯蓄" },
              { value: "other", name: "その他" },
            ]}
            name="accountType" // Add name for Select
            onChange={handleChange} // Add onChange handler if needed
          />
          <Input
            label="口座番号"
            option="[任意]"
            type="text"
            placeholder="1234567"
            name="accountNumber"
            inputmode="numeric"
            maxlength="10"
            alert={errors.accountNumber}
            value={bankData.accountNumber}
            onChange={handleChange}
          />
        </div>
        <Input
          label="口座名義（カナ）"
          option="[任意]"
          type="text"
          placeholder="カ）ソレモ"
          name="accountName"
          alert={errors.accountName}
          value={bankData.accountName}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default BankAccount;
