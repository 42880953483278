import React from "react";

import Input from "../../../components/form-item/Input";

const MySiteWorks = () => {
  return (
    <div className="u-col u-gap16">
      <p>作品登録の説明文</p>

      <div className="u-col u-w100">
        <div className="bodytext-11"></div>
        <div className="u-row u-gap4">
          <div className="heading">アップロード</div>
          <span className="label8 u-text-light-gray">[任意]</span>
        </div>
        <div className="u-col u-gap8 u-w100">
          <div className="u-col u-gap8 u-w100">
            <div
              style={{
                width: "100%",
                height: "5rem",
                backgroundColor: "gray",
                borderRadius: 4,
              }}
            >
              ファイル選択用コンポーネント
            </div>
          </div>
        </div>
      </div>

      <div className="u-col u-w100">
        <Input
          label="作品名"
          option="[必須]"
          type="text"
          placeholder="a sing for you"
          width="100%"
          append=""
          name=""
        />
      </div>

      <div className="u-col u-w100">
        <Input
          label="制作年"
          option="[任意]"
          type="text"
          placeholder="2024"
          width="80px"
          append="年"
          name=""
        />
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          複数アーティストで制作した作品を掲載する場合は、クレジットを登録しましょう。
        </div>
        <Input
          label="クレジット"
          option="[任意]"
          type="text"
          placeholder="Music and Arrangement by ... Lyrics by ..."
          width="100%"
          append=""
          name=""
        />
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          テーマや狙い、それがどのように表現されているかを伝えましょう。
        </div>
        <Input
          label="解説"
          option="[任意]"
          type="text"
          placeholder="聴く人に寄り添えるようなメロディーを意識しました。"
          width="100%"
          append=""
          name=""
        />
      </div>

      <div className="u-col u-w100">
        {/* <div className="bodytext-11">ワードの区切りは、#で設定してください。</div> */}
        <Input
          label="ハッシュタグ"
          option="[任意]"
          type="text"
          placeholder="ワードの区切りは「#」で設定してください。 #ピアノ"
          width="100%"
          append=""
          name=""
        />
      </div>

      <div className="u-col u-w100">
        <div className="heading">
          販売方針<span className="u-pl4 label8 u-text-blue u-ml8">[必須]</span>
        </div>

        <div className="u-w100 u-col u-gap8 c-radio">
          <div className="u-col u-100">
            <div className="u-row u-100 u-gap8">
              <input
                type="radio"
                name="sales-policy"
                id="sold-as-is"
                defaultChecked=""
              />
              <label htmlFor="sold-as-is" className="heading-13">
                そのまま販売
              </label>
            </div>
            <div className="bodytext-11">
              作品に手を加えず、買取（著作権譲渡）で販売します。
            </div>
          </div>

          <div className="u-col u-100">
            <div className="u-row u-gap8">
              <input
                type="radio"
                name="sales-policy"
                id="semi-order"
                defaultChecked=""
              />
              <label htmlFor="semi-order" className="heading-13">
                セミオーダーで販売
              </label>
            </div>
            <div className="bodytext-11">
              作品の一部をカスタマイズして販売します。購入された後も、販売を続けることができます。
            </div>
            {/* label分の余白が表示されてしまっている↓ */}
            <Input
              type="text"
              placeholder="歌詞を変えて注文"
              width="100%"
              append=""
              name=""
            />
          </div>

          <div className="u-col u-100">
            <div className="u-row u-gap8">
              <input
                type="radio"
                name="sales-policy"
                id="user-rights-only"
                defaultChecked=""
              />
              <label htmlFor="user-rights-only" className="heading-13">
                利用権を販売
              </label>
            </div>
            <div className="bodytext-11">
              作品をそのまま、非独占で利用権販売します。購入された後も、販売を続けることができます。
            </div>
          </div>

          <div className="u-col u-100">
            <div className="u-row u-gap8">
              <input
                type="radio"
                name="sales-policy"
                id="not-for-sale"
                defaultChecked="checked"
              />
              <label htmlFor="not-for-sale" className="heading-13">
                販売しない
              </label>
            </div>
            <div className="bodytext-11">
              サンプル視聴のみの取り扱いとします。
            </div>
          </div>
        </div>
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          設定価格以下のオファーは届かないようにできます。
        </div>
        <Input
          label="開始価格"
          option="[任意]"
          type="text"
          placeholder="50,000"
          width="100%"
          append="円（税抜）"
          name=""
        />
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          設定価格を提示すれば、すぐ売却できるようにします。
        </div>
        <Input
          label="終了価格"
          option="[任意]"
          type="text"
          placeholder="200,000"
          width="100%"
          append="円（税抜）"
          name=""
        />
      </div>

      <div className="u-col u-w100">
        <div className="bodytext-11">
          掲載開始期間と終了期間を設定し、オークションスタイルで販売しましょう。
        </div>
        <Input
          label="開始日時"
          option="[任意]"
          type="text"
          placeholder="2024/10/28 10:19"
          width="100%"
          append=""
          name=""
        />
        <Input
          label="終了日時"
          option="[任意]"
          type="text"
          placeholder="2024/11/28 10:19"
          width="100%"
          append=""
          name=""
        />
      </div>
    </div>
  );
};

export default MySiteWorks;
