import React from "react";

import Button from "../../../components/button/Button";

const PaymentDetails = () => {
  return (
    <div className="u-col u-gap16">
      <p>支払明細の確認とダウンロードができます。</p>
      <div className="u-w100">
        <div className="heading">支払明細書</div>
        <div className="u-w100 c-group" style={{ overflowX: "scroll" }}>
          <table
            className="u-w100"
            style={{ whiteSpace: "nowrap", tableLayout: "fixed" }}
          >
            <thead
              className="bodytext-13 u-text-light-gray"
              style={{ textAlign: "left" }}
            >
              <tr>
                <th style={{ width: "160px" }}></th>
                <th style={{ width: "96px" }}>申請日</th>
                <th style={{ width: "96px" }}>支払日</th>
                <th style={{ width: "128px" }}>金額（税込）</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ height: "48px" }}>
                <td>
                  <Button
                    name="PDFを閲覧"
                    size="small"
                    color="secondary"
                    isDisabled="true"
                  />
                </td>
                <td>
                  <div className="bodytext-13">24/10/10</div>
                </td>
                <td>
                  <div className="bodytext-13">処理中</div>
                </td>
                <td>
                  <div className="bodytext-13">10,000,000,000円</div>
                </td>
              </tr>
              <tr style={{ height: "48px" }}>
                <td>
                  <Button
                    name="PDFを閲覧"
                    size="small"
                    color="secondary"
                    isDisabled="true"
                  />
                </td>
                <td>
                  <div className="bodytext-13">24/9/30</div>
                </td>
                <td>
                  <div className="bodytext-13">処理中</div>
                </td>
                <td>
                  <div className="bodytext-13">200,000円</div>
                </td>
              </tr>
              <tr style={{ height: "48px" }}>
                <td>
                  <Button name="PDFを閲覧" size="small" color="secondary" />
                </td>
                <td>
                  <div className="bodytext-13">24/9/25</div>
                </td>
                <td>
                  <div className="bodytext-13">24/9/27</div>
                </td>
                <td>
                  <div className="bodytext-13">300,000円</div>
                </td>
              </tr>
              <tr style={{ height: "48px" }}>
                <td>
                  <Button name="PDFを閲覧" size="small" color="secondary" />
                </td>
                <td>
                  <div className="bodytext-13">24/9/23</div>
                </td>
                <td>
                  <div className="bodytext-13">24/9/24</div>
                </td>
                <td>
                  <div className="bodytext-13">400,000円</div>
                </td>
              </tr>
              <tr style={{ height: "48px" }}>
                <td>
                  <Button name="PDFを閲覧" size="small" color="secondary" />
                </td>
                <td>
                  <div className="bodytext-13">24/9/18</div>
                </td>
                <td>
                  <div className="bodytext-13">24/9/20</div>
                </td>
                <td>
                  <div className="bodytext-13">500,000円</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
