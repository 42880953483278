import React, { useState } from "react";
import styled from "styled-components";

const StyledTextarea = styled.textarea.attrs({
  className: "bodytext",
})`
  line-height: 150%;
`;

const StyledCounter = styled.div.attrs({
  className: "bodytext-11 u-w100",
})`
  text-align: right;
  color: ${(props) =>
    props.isOverLimit ? "var(--soremo-green)" : "var(--soremo-light-gray)"};
`;

const Textarea = React.forwardRef((props, ref) => {
  const { name, id, rows, placeholder, maxLength } = props;
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (maxLength && newValue.length > maxLength) {
      //   alert('文字数の上限を超えています');
      // 上限を超えないように値を切り詰める場合は以下を使用
      setValue(newValue.slice(0, maxLength));
    } else {
      setValue(newValue);
    }
  };

  return (
    <>
      <StyledTextarea
        name={name}
        id={id}
        rows={rows}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        ref={ref}
      />
      {maxLength && (
        <StyledCounter isOverLimit={value.length >= maxLength}>
          {value.length}/{maxLength}
        </StyledCounter>
      )}
    </>
  );
});

export default Textarea;
