// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useNavigate } from "react-router-dom";

import {
  getAuthTokenFromCookies,
  setAuthTokenToCookies,
} from "../../common/utils";
import { HOSTNAME } from "../../constant/host";

// Define a service using a base URL and expected endpoints
export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: fetchBaseQuery({
    baseUrl: HOSTNAME + "/api/get-profile",
    prepareHeaders: (headers) => {
      // Get the auth token from cookies
      const token = getAuthTokenFromCookies();
      // If we have a token, add it to the headers
      if (token) {
        headers.set("Authorization", `Token ${token}`);
      }

      return headers;
    },
    // Handle redirection based on status
    baseQuery: async (args, api, extraOptions) => {
      const result = await fetchBaseQuery(args, api, extraOptions);
      const location = window.location.pathname; // Get current page
      const navigate = useNavigate();

      // If user is unauthorized (status 401)
      if (result.error && result.error.status === 401) {
        // Redirect to login if not on homepage or login page
        if (location !== "/" && location !== "/accounts/login") {
          navigate("/accounts/login");
        }
      }

      return result;
    },
  }),
  endpoints: (builder) => ({
    getProfileByName: builder.query({
      query: () => ``,
    }),
  }),
});

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${HOSTNAME}/api/login`, // Use HOSTNAME for the base URL
    credentials: "include", // To include cookies in the request if needed
    // Optionally prevent redirects
    redirect: "manual",
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (credentials) => {
        // Create FormData object and append the credentials
        const formData = new FormData();
        formData.append("username", credentials.username);
        formData.append("password", credentials.password);

        return {
          url: `${HOSTNAME}/api/login`,
          method: "POST",
          body: formData, // Send FormData as the body
        };
      },
      // Handle the response and store the token
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const token = data.token;
          if (token) {
            setAuthTokenToCookies(token); // Save token to cookies
          }
        } catch (error) {
          // console.error("Login failed:", error);
        }
      },
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetProfileByNameQuery } = profileApi;
export const { useLoginUserMutation } = loginApi;
