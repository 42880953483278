import React from "react";

import TopPageLayout from "./index";
import ProjectPageLayout from "./project";

const routes = [
  {
    path: "/top",
    exact: true,
    component: <TopPageLayout />,
  },
  {
    path: "/top/project/:projectId", // Dynamic segment added here
    exact: true,
    // hideGlobalWraper: true,
    component: <ProjectPageLayout />,
  },
];

export default routes;
