import React, { useState, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";

import {
  setScrollUp,
  setScrollDown,
  resetScroll,
} from "../../app/actions/layout/scroll";
import GlobalMenu from "../global-menu";

const GlobalWrapper = ({ children, isHideTopBar = false }) => {
  const dispatch = useDispatch();
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);

  useLayoutEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      // Compare previous scroll position with the current to determine direction
      if (currentScrollPos > prevScrollPos) {
        dispatch(setScrollDown());
      } else if (currentScrollPos < prevScrollPos) {
        dispatch(setScrollUp());
      } else {
        dispatch(resetScroll());
      }

      setPrevScrollPos(currentScrollPos);
    };

    // Attach scroll listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the scroll listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, dispatch]); // Add dependencies to prevent stale closures

  return (
    <div>
      <GlobalMenu isHideTopBar={isHideTopBar} />
      {children}
    </div>
  );
};

export default GlobalWrapper;
