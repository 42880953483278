import React, { useState } from "react";
import styled from "styled-components";

import MessengerTab from "./MessengerTab";
import TopLeftSidebar from "./TopLeftSidebar"; // Import your sidebar component
import ProjectBudgetInfo from "./ProjectBudgetInfo"; // Import your budget info component
import ProjectSetting from "./ProjectSetting";
import SwitchComponent from "../../../../../components/form-item/SwitchComponent";
import LeftSideBar from "./LeftSidebar";
import ProjectUpdateList from "./ProjectUpdateList";
import ProjectFinishedList from "./ProjectFinishedList";

const SidebarToggleButton = styled.span`
  position: absolute;
  top: 10px;
  left: 20px;
  cursor: pointer;
  transition:
    transform 0.2s,
    color 0.2s;

  &:hover {
    color: var(--soremo-blue);
    transform: scale(1.2);
  }
`;

const ProjectItemDetail = ({ productScenes, project, user, viewOnly }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [valueData, setValueData] = useState(0);

  return (
    <div
      className="project-item__content refactor"
      id="projectItemDetail"
      style={{ position: "relative" }}
    >
      <div
        className="navigation-top-app-bar"
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "8px 16px",
        }}
      >
        <SwitchComponent
          isTopBar={true}
          defaultValue={valueData}
          options={[
            {
              value: 0,
              label: "進行中",
            },
            {
              value: 1,
              label: "すべて",
            },
          ]}
          onChange={(e) => setValueData(e)}
        />
      </div>
      <SidebarToggleButton
        id="left-sidebar-open"
        className="material-symbols-rounded"
        onClick={(e) => setMenuVisible(true)}
      >
        view_sidebar
      </SidebarToggleButton>
      <LeftSideBar
        setVisible={setMenuVisible}
        visible={menuVisible}
        productScenes={productScenes}
        project={project}
        user={user}
        titlePage={project?.get_name_in_page_title}
      />
      <TopLeftSidebar productScenes={productScenes} project={project} />

      <div className="project-tab project-tab-new active" data-tab="new">
        <div className="project-item__product-comment">
          {/* Product comment content can be added here */}
        </div>
      </div>

      <div
        className="project-tab project-tab-product-comment-old"
        data-tab="product-comment"
      >
        <div className="project-item__video-list">
          {/* Video list content can be added here */}
        </div>
      </div>

      <div
        className={`project-tab project-tab-progress-old ${viewOnly ? "view_only cannot-check" : ""}`}
        data-tab="progress"
      >
        <div className="tab--video-progress tab--video-all">
          <ProjectUpdateList
            updatedScenes={project?.updated_scene_titles || []}
            user={user}
          />
          {user?.role === "admin" && (
            <div className="tab--video-watting_checkback processing-list-item d-none-chapter chapter-block">
              <ProjectFinishedList
                sceneList={project?.finished_scene}
                user={user}
              />
            </div>
          )}
        </div>
      </div>

      <MessengerTab />
      <ProjectSetting />
      <ProjectBudgetInfo />
    </div>
  );
};

export default ProjectItemDetail;
