import React, { useRef } from "react";

import AutoFill from "../../../components/autofill/AutoFill";
import Textarea from "../../../components/form-item/Textarea";
import SwitchComponent from "../../../components/form-item/SwitchComponent";

const OfferConditions = () => {
  const textareaRef = useRef(null);

  const handleSelectContent = (content) => {
    if (textareaRef.current) {
      textareaRef.current.value = content;
    }
  };

  return (
    <>
      <div className="u-col u-gap32 u-w100">
        <div className="u-col u-gap16 u-w100">
          <div className="heading-18-spacing">クライテリア</div>
          <div className="u-col u-gap8 u-w100">
            <AutoFill
              group="criteria"
              nowrap={false}
              onSelectContent={handleSelectContent}
            />
            <Textarea
              name="criteria"
              id="criteria"
              ref={textareaRef}
              rows={5}
              placeholder="プロジェクトが私にとってエキサイティングかどうかで判断します。"
              maxLength={400}
            />
          </div>
        </div>
        <div className="u-col u-gap16">
          <div className="heading-18-spacing">トレードオフスライダー</div>
          <p>
            オファーを増やしたい場合は「左」に、条件を良くしていきたい場合は「右」に設定。ここでの設定は、ダイナミックプライシングの最適化に影響します。
          </p>
          <div className="u-w100 u-col c-group u-gap8">
            {/* ラジオボタン版 */}
            <div className="u-w100 u-row-between c-radio">
              <input
                type="radio"
                name="slider"
                id="one"
                defaultValue="slider"
              />
              <input
                type="radio"
                name="slider"
                id="two"
                defaultValue="slider"
              />
              <input
                type="radio"
                name="slider"
                id="three"
                defaultValue="slider"
                defaultChecked="checked"
              />
              <input
                type="radio"
                name="slider"
                id="four"
                defaultValue="slider"
              />
              <input
                type="radio"
                name="slider"
                id="five"
                defaultValue="slider"
              />
            </div>
            <div className="u-w100 u-row-between u-text-light-gray">
              <div className="bodytext-11">オファーを増やしたい</div>
              <div className="bodytext-11">条件を良くしたい</div>
            </div>
          </div>
        </div>

        <div className="u-col u-gap16">
          <div className="heading-18-spacing">公開範囲</div>
          <div className="u-col u-gap8">
            <p>
              ONにするとがプロデューサー、ディレクターだけでなく、クリエイター全体にも開示します。
            </p>
            <SwitchComponent
              options={[
                { label: "公開", value: "open" },
                { label: "公開先を限定", value: "close" },
              ]}
              defaultValue="close"
              onChange={() => {}}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferConditions;
